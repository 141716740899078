<template>
  <div>
    <FilterDashboard :farms="farms" @search="search" />

    <base-loading v-if="loading" />
    <div v-else>
      <v-row align="center" justify="center">
        <div class="chart-table">
          <h2
            class="text-xl-h4 font-weight-medium text-uppercase mt-4"
            style="color: #1b2565"
          >
            Inconformidades
          </h2>
          <v-row class="pb-4 mt-1" justify="center">
            <g-chart
              id="inconformidades"
              type="PieChart"
              style="height: 50vh; width: 100vh"
              :data="[
                ['Categoria', 'Quantidade'],
                [
                  'Ambiente de descanso dos animais com acúmulo de barro',
                  dashboardReportData.percentege.step_8_ambiental_de_descanso,
                ],
                [
                  'Sala de ordenha sem higienização adequada',
                  dashboardReportData.percentege.step_8_sala_de_ordenha,
                ],
                [
                  'Limpeza do equipamento de ordenha inadequada',
                  dashboardReportData.percentege.step_8_limpeza_do_equipamento,
                ],
                [
                  'Desregulagem do tanque de resfriamento (termostato)',
                  dashboardReportData.percentege.step_8_desregulagem_do_tanque,
                ],
                [
                  'Tanque não refrigera o leite a 4 ºC em duas horas',
                  dashboardReportData.percentege.step_8_tanque_de_resfriamento,
                ],
                [
                  'Coleta do leite superior a 48 horas',
                  dashboardReportData.percentege
                    .step_8_coleta_do_leite_superior_a_48_horas,
                ],
                [
                  'Atraso no resfriamento do leite (Balde ao pé, Ordenha manual)',
                  dashboardReportData.percentege
                    .step_8_atraso_no_resfriamento_do_leite,
                ],
              ]"
              :options="chartOptions"
              :events="chartEvents"
            />
          </v-row>
        </div>
        <v-col cols="11" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CONTROLE DE VISITAS
          </h2>
          <div class="chart-table">
            <table-graph
              id="table-graph-1"
              :items="dataVisitsMade"
              :headers="headersVisitsMade"
            />
            <base-bar-graph
              id="control-graph"
              :chart-data="chartDataVisitsMade"
              :style="styles"
            />
          </div>
        </v-col>
        <v-col cols="11" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            EFETIVIDADE
          </h2>
          <div class="chart-table">
            <table-graph
              id="table-graph-2"
              :items="dataEfficiency"
              :headers="headersEfficiency"
            />
          </div>
        </v-col>

        <v-col
          v-for="(dataEfficiencyVisit, index) in dataEfficiencyVisits"
          :key="index"
          cols="11"
          class="graficoConsultor"
        >
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            VISITAS REALIZADAS - {{ dataEfficiencyVisit['year'] }}
          </h2>
          <div class="chart-table">
            <table-graph
              id="table-graph-3"
              :items="dataEfficiencyVisit['content']"
              :headers="dataEfficiencyVisit['headers']"
            />
          </div>
        </v-col>

        <v-col
          v-for="(dataEfficiencyVisit, index) in dataEfficiencyVistisByAVG"
          :key="index + dataEfficiencyVisit['year']"
          cols="11"
          class="graficoConsultor"
        >
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CPP MÉDIA GRUPO - {{ dataEfficiencyVisit['year'] }}
          </h2>
          <div class="chart-table">
            <table-graph
              id="table-graph-4"
              :items="dataEfficiencyVisit['content']"
              :headers="dataEfficiencyVisit['headers']"
            />
          </div>
        </v-col>

        <v-col cols="11" class="align-center justify-center d-flex">
          <v-btn
            style="color: white; border-radius: 8px"
            color="primary"
            dark
            class="mr-4"
            :loading="loadingPdf"
            @click="print()"
          >
            <v-icon left>mdi-file-document</v-icon>
            <span style="font-size: 16px; font-weight: 600">Exportar PDF</span>
          </v-btn>

          <v-btn
            style="color: white; border-radius: 8px"
            color="primary"
            dark
            :loading="loadingExcel"
            :disabled="loadingExcel"
            @click="sheet()"
          >
            <v-icon left>mdi-file-excel</v-icon>
            <span style="font-size: 16px; font-weight: 600"
              >Exportar planilha</span
            >
          </v-btn>
        </v-col>
      </v-row>

      <DialogInconformidades
        v-model="dialogInconformidades"
        :title="dialogTitle"
        :items="inconformidadesList"
      />
    </div>
  </div>
</template>

<script>
import _ from 'underscore'
import TableGraph from '../../../components/base/TableGraph.vue'
import { CONFIG } from '../../../services/reports/dashboard/DashboardBase'
import { create } from './dashboard/pdf-export'

import {
  activeProducersList,
  assosiationLabel,
  monthShortLabel,
  renderDatasetGraph,
  renderDatasetTable,
} from '../../../utils/dashboard/dashboard'
import FilterDashboard from '../filter/index.vue'

import DialogInconformidades from '../../../components/reports/DialogInconformidades.vue'
import {
  dataEfficiency,
  dataEfficiencyByAVG,
  dataEfficiencyByVisits,
  headersEfficiency,
} from '../../../utils/dashboard/efectivenessDashboard'
import { createSheet } from './dashboard/excel-export'

export default {
  name: 'Dashboard',
  components: {
    TableGraph,
    FilterDashboard,
    DialogInconformidades,
  },

  data() {
    return {
      CONFIG,
      activeProducersList,
      headersEfficiency,
      loading: true,
      farms: [],

      form: {
        produtor: '',
        consultor: '',
        agroindustria: '',
        fazenda: '',
        activeProducers: activeProducersList[0],
        dataInicio: this.moment()
          .subtract(11, 'months')
          .toISOString(true)
          .substr(0, 10),
        dataFim: this.moment().toISOString(true).substr(0, 10),
      },

      responsiveOptions: [
        [
          'screen and (max-width: 640px)',
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          },
        ],
      ],

      styles: {
        backgroundColor: '#f1f1f1',
      },

      headersVisitsMade: [],

      chartDataVisitsMade: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      optionsCPP1: {
        seriesBarDistance: 10,
      },

      dataEfficiency: [],
      dataEfficiencyVisits: [],
      dataEfficiencyVistisByAVG: [],

      chartOptions: {
        legend: {},
        chartArea: { height: '85%', width: '100%' },
        tooltip: { isHtml: true },
      },

      chartEvents: {
        click: event => {
          const targetID = Number(event.targetID.split('#')[1])

          if (isNaN(targetID)) return

          this.refactorInconformidadesList(targetID)

          this.dialogInconformidades = true
        },
      },

      loadingPdf: false,
      loadingExcel: false,

      dialogInconformidades: false,
      inconformidadesList: [],
      dialogTitle: '',
    }
  },

  created() {
    this.dashboardReport()
  },

  methods: {
    renderDatasetGraph,
    renderDatasetTable,

    dashboardReport() {
      if (!headersEfficiency) return
      return new Promise((resolve, reject) => {
        this.loading = true
        this.api.get
          .getDashboardCPP(
            this.form.dataInicio,
            this.form.dataFim,
            this.form.produtor,
            this.form.fazenda,
            this.form.agroindustria,
            this.form.consultor,
            3,
            this.form.activeProducers,
          )
          .then(async response => {
            this.dashboardReportData = response.data

            await this.filter(this.dashboardReportData.efectiveness)

            this.dataEfficiency = dataEfficiency(
              this.dashboardReportData.efectiveness,
            )

            this.dataEfficiencyVisits = dataEfficiencyByVisits(
              this.dashboardReportData.efectiveness,
            )

            this.dataEfficiencyVistisByAVG = dataEfficiencyByAVG(
              this.dashboardReportData.efectiveness,
            )

            const resultVisitsMade = _.groupBy(
              this.dashboardReportData.visitsMade,
              item => item.year,
            )

            this.headersVisitsMade = [
              { text: '', value: 'year' },
              ...Object.keys(resultVisitsMade).map(item => {
                return { text: item, value: item }
              }),
              ...assosiationLabel,
            ]

            this.chartDataVisitsMade.datasets = this.renderDatasetGraph(
              resultVisitsMade,
            )
            const result = this.renderDatasetTable(
              this.chartDataVisitsMade.datasets,
            )

            const resultData = {}

            Object.entries(resultVisitsMade).forEach(([key, value]) => {
              let sum = 0
              value.forEach(item => {
                sum += item.avg
              })
              resultData[key] = sum
            })

            result.push({
              year: 'Realizado no ano',
              class: 'blue-row',
              ...resultData,
            })

            this.dataVisitsMade = result

            this.loading = false
            return resolve()
          })
          .catch(error => {
            return reject(error)
          })
      })
    },

    async filter(data) {
      const farms = [
        ...new Set(
          data?.map(item => {
            return item.farmId
          }),
        ),
      ]

      this.farms = farms
    },

    async search(data) {
      this.form = data

      this.dashboardReport()
    },

    getDashboard() {
      this.loading = true
      this.dashboardReport().catch(() => {
        this.Swal.fire({
          title: 'Erro',
          text: 'Erro ao carregar dados',
          type: 'error',
        }).finally(() => {
          this.loading = false
        })
      })
    },

    async print() {
      this.loadingPdf = true

      try {
        const table1 = document
          .getElementById('table-graph-1')
          .getElementsByTagName('table')[0]
        const table2 = document
          .getElementById('table-graph-2')
          .getElementsByTagName('table')[0]
        const table3 = document
          .getElementById('table-graph-3')
          .getElementsByTagName('table')[0]
        const table4 = document
          .getElementById('table-graph-4')
          .getElementsByTagName('table')[0]

        const images = [
          {
            title: 'INCONFORMIDADES',
            image: document.getElementById('inconformidades'),
          },
          {
            title: 'CONTROLE DE VISITAS',
            image: document.getElementById('control-graph'),
          },
        ]

        const config = {
          ...CONFIG,
          title: 'Dashboard CPP',
          consultor: 'Teste',
        }

        await create({
          config,
          tables: [table1, table2, table3, table4],
          images,
        })
      } catch (error) {
        this.Toast().fire({
          title: 'Erro ao exportar PDF',
          type: 'error',
        })
      } finally {
        this.loadingPdf = false
      }
    },

    async sheet() {
      this.loadingExcel = true
      try {
        createSheet({
          data: {
            dataEfficiencyVisits: this.dataEfficiencyVisits,
            dataEfficiencyVistisByAVG: this.dataEfficiencyVistisByAVG,
            dataVisitsMade: [
              {
                headers: this.headersVisitsMade,
                content: this.dataVisitsMade,
                year: this.dataEfficiencyVisits[0].year,
              },
            ],

            dataUnconformities: this.dashboardReportData.percentegeData,
            dataEfficiency: this.dataEfficiency,
          },

          filename: 'Dashboard CPP',
        })
      } catch (error) {
        this.Toast().fire({
          title: 'Erro ao exportar planilha',
          type: 'error',
        })
      } finally {
        this.loadingExcel = false
      }
    },

    refactorInconformidadesList(id) {
      const mapInconformidades = key =>
        this.dashboardReportData.percentegeData.filter(
          item => item[key] === '1',
        )

      const setInconformidadesListAndTitle = (list, title) => {
        this.inconformidadesList = list
        this.dialogTitle = title
      }

      switch (id) {
        case 0:
          setInconformidadesListAndTitle(
            mapInconformidades('step_8_ambiental_de_descanso'),
            'Ambiente de descanso dos animais com acúmulo de barro',
          )
          break

        case 1:
          setInconformidadesListAndTitle(
            mapInconformidades('step_8_sala_de_ordenha'),
            'Sala de ordenha sem higienização adequada',
          )
          break

        case 2:
          setInconformidadesListAndTitle(
            mapInconformidades('step_8_limpeza_do_equipamento'),
            'Limpeza do equipamento de ordenha inadequada',
          )
          break

        case 3:
          setInconformidadesListAndTitle(
            mapInconformidades('step_8_desregulagem_do_tanque'),
            'Desregulagem do tanque de resfriamento (termostato)',
          )
          break

        case 4:
          setInconformidadesListAndTitle(
            mapInconformidades('step_8_tanque_de_resfriamento'),
            'Tanque não refrigera o leite a 4 ºC em duas horas',
          )
          break

        case 5:
          setInconformidadesListAndTitle(
            mapInconformidades('step_8_coleta_do_leite_superior_a_48_horas'),
            'Coleta do leite superior a 48 horas',
          )
          break

        case 6:
          setInconformidadesListAndTitle(
            mapInconformidades('step_8_atraso_no_resfriamento_do_leite'),
            'Atraso no resfriamento do leite (Balde ao pé, Ordenha manual)',
          )
          break

        default:
          break
      }
    },
  },
}
</script>

<style>
svg > g:last-child > g:last-child {
  pointer-events: none;
}
div.google-visualization-tooltip {
  pointer-events: none;
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>
